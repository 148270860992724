import React from 'react';


const Works = () => {
    return (
        <>
            <div className="container-fluid feature">
                <div className="container">
                    <div className="section-work1 all">
                        <span>How It Works</span>
                    </div>
                    <hr /><br />
                    <div className="col-md-3">
                        <div className="btx-1">
                            <p align="center">
                                <img className="img-responsive" src="img/works/icon5.png" alt='Register Ro Services' />
                            </p>

                            <h4>Call &amp; Register Ro Services</h4>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="btx-1">
                            <p align="center">
                                <img className="img-responsive" src="img/works/icon6.png" alt='Get Technician Confirmation' />
                            </p>

                            <h4>Get Technician Confirmation</h4>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="btx-1">
                            <p align="center">
                                <img className="img-responsive" src="img/works/icon7.png" alt='Schedule Visit Time' />
                            </p>

                            <h4>Schedule Visit Time</h4>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="btx-1">
                            <p align="center">
                                <img className="img-responsive" src="img/works/xiocn-8.png" alt='Relax' />
                            </p>

                            <h4>Relax</h4>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Works;