export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className="container-fluid feature">
        <div className="container">
          <div className="row">
            <div className="col-md-3 ser-11">
              <div className="feabox-1">
                <p align="center"><img alt="" data-pagespeed-url-hash="690364696" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" src="img/features/service1.png" title="" /></p>

                <p className="features-1">SERVICING</p>
              </div>
            </div>

            <div className="col-md-3 ser-11">
              <div className="ser-2">
                <p align="center"><img alt="" data-pagespeed-url-hash="2254949671" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" src="img/features/icon2.png" title="" /></p>

                <p className="features-1">INSTALLATION</p>
              </div>
            </div>

            <div className="col-md-3 ser-11">
              <div className="ser-3">
                <p align="center"><img alt="" data-pagespeed-url-hash="2549449592" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" src="img/features/icon3.png" title="" /></p>

                <p className="features-1">MAINTENANCE</p>
              </div>
            </div>

            <div className="col-md-3 ser-11">
              <div className="ser-4">
                <p align="center"><img alt="" data-pagespeed-url-hash="2843949513" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" src="img/features/icon4.png" title="" /></p>

                <p className="features-1">REPAIRS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
