import React from 'react'

var d = new Date();
var currYear = d.getFullYear();
export const Copyright = (props) => {
    return (
      
        <div class="container-fluid cpr-bottom">
        <div class="container">
        <p style={{alignItems:'center'}}>© Copyright {currYear} RO Service India . All rights reserved &nbsp; | &nbsp; <a href="/privacy-policy">Privacy Policy </a></p>
        </div>
        </div>
      
    );
  };
  