import React from 'react';

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row intro-text'>
              

             

              
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
